<script>
import { useNotificationStore } from '~/stores/account/notifications.store';
import helper from "~/resources/js/libraries/helper";

export default {
  name: "MobileNotificationCard",
  props: {
    data: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      notificationStore: useNotificationStore(),
      router: useRouter(),
      helper: helper
    }
  },
  methods: {
    async routeToUrl() {
      await this.notificationStore.markRead(this.data._id);
      window.location.href = this.data.url;
    }
  },
}
</script>

<template>
  <div @click="routeToUrl" class="border-b border-gray-200 p-3 cursor-pointer"
    :class="{ [data?.read_at ? 'bg-white' : 'bg-indigo-50']: true }">
    <div class="flex items-center gap-4 relative pl-4">
      <span v-if="!data?.read_at"
        class="absolute -left-1 top-1/2 h-3 w-3 -translate-y-1/2 bg-indigo-600 rounded-full"></span>
      <img :src="data.picture" :alt="data.full_name" class="rounded-full w-16 h-16 object-cover self-center">
      <div class="flex flex-col">
        <h1 class="font-medium">{{ data.title }}</h1>
        <div class="opacity-80 text-gray-700 text-sm" v-html="data.body"></div>
        <p class="opacity-80 text-gray-500 text-sm mt-1">
          <i class="fa-solid fa-clock  me-2"></i>{{ helper.timeAgo(data.created_at) }}
        </p>
      </div>
    </div>
  </div>
</template>